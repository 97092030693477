import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { RootState } from 'app/redux_store';
import HierarchyAssignment from "../../../services/hierarchyAssiment.service";
import ProductmasterService from "services/productmaster.service";
import { logicToggle, setSortierungNodeValues} from './hierarchyAssignment.slice';
import { IUniqueKeyAndPageNumber } from '../../../models/productmastertreeview/IUniqueKeyAndPageNumber';
import { GetSortValueParameter, IProductPropertyNodeModel, ProductSortPreferencesSnapshotParameter, SaveSortProductsParameter } from 'models/selectproductmanager/setproducts/productPropertyNodeModel';
import { clearAvailableAndPossibleProductmasterArray } from '../../productmaster/redux/productmaster.slice';



export const getPropertyNodesComponent = createAsyncThunk(
    'productmastertreeview/ProductManagerTreeView/getpropertynodes',
    async ( data:IUniqueKeyAndPageNumber, { getState, dispatch}) => {
        
        if (data) {
            await dispatch(logicToggle({ unique: data.unique, paging: data.paging }));
            await dispatch(clearAvailableAndPossibleProductmasterArray());
            
        }
        const state = getState() as RootState;
        const response = await HierarchyAssignment.getPropertyNodes(state.selectTreeView.dataSku.hierarchyProperty);
        handleResponseErrors(response, "getPropertyNodes");
        return response;
    }
);



export const DeleteSearchCriteria = createAsyncThunk(
    'articletunks/articletunks/postSearchCriteria',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState
        const response = await ProductmasterService.GetProductmaster(state.selectTreeView.data.SearchCriteria);
        handleResponseErrors(response, "articletunks");
        return response;
    }
);

export const ResultpaginatedSkuList = createAsyncThunk(
    'articletunks/articletunks/PostpaginatedSkuList',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState
        const response = await HierarchyAssignment.paginatedSku(state.selectTreeView.dataSku.determine);
        handleResponseErrors(response, "paginatedSkuList");
        return response;
    }
);


export const SaveSortProducts = createAsyncThunk(
    'articletunks/articletunks/SaveProductSort',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState
        var value : SaveSortProductsParameter = { nodes :state.selectTreeView.data.SearchCriteriaQuery.productNodesModel, sortValue:state.selectTreeView.dataSort.sortTextBoxValue, selectedNode: state.selectTreeView.data.setSortProductPropertyNode.productPropertyNodeModel }
        const response = await HierarchyAssignment.SaveSortProducts(value);
        handleResponseErrors(response, "saveProductSort");
        return response;
    }
);

export const GetSortValue = createAsyncThunk(
    'articletunks/articletunks/GetSortValue',
    async (node: IProductPropertyNodeModel, { getState, dispatch }) => {
        var value: GetSortValueParameter = { node:node}
        const response = await HierarchyAssignment.GetSortValue(value);
        await dispatch(setSortierungNodeValues({ productPropertyNodeModel: node, id: '', treeViewId: '' }));
        handleResponseErrors(response, "getSortValue");
        return response;
    }
);


export const GetProductSortPreferencesSnapshot = createAsyncThunk(
    'articletunks/articletunks/GetProductSortPreferencesSnapshot',
    async (node: IProductPropertyNodeModel, { getState, dispatch }) => {

        const state = getState() as RootState
        let data: ProductSortPreferencesSnapshotParameter = { nodes: state.selectTreeView.dataSort.productSortPreferencesParameter.nodes, type: node.type }
        const response = await HierarchyAssignment.GetProductSortPreferencesSnapshot(data);
       
        handleResponseErrors(response, "getProductSortPreferencesSnapshot");
        return response;
    }
);