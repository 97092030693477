import { removeLastSelectionWhenItsAlreadySet } from "../redux/hierarchyAssignment.reducer";

export const setTreeViewTable = (showTable: boolean, dataCheckDisplayCircularProgress: boolean) => {


    if (showTable === true && dataCheckDisplayCircularProgress === true) {

        return true;
    }


    if (showTable === false && dataCheckDisplayCircularProgress === false) {

        return false
    }

    return false
}

export const setExpandValue = (expand: string[], searchCriterion: string, oldexpandedstate: string[]) :string[] => {
    const indexOfLastUniqueKeyWithMatches = expand.findIndex(x => x.endsWith(searchCriterion));

    if (indexOfLastUniqueKeyWithMatches !== -1 && expand.length > 1) {
        expand = expand.filter((x, index) => {
            return !(index >= indexOfLastUniqueKeyWithMatches && x !== searchCriterion);
        });
    }

    if (oldexpandedstate.length > expand.length) {
        let index = expand.length - 1;
        if (expand[index] !== searchCriterion) {
            expand.pop();
        }
      
    }

    if (expand.length === 1 && oldexpandedstate.length >= 1) {

        if (expand[0] === searchCriterion) {

            expand.pop()
        }
    }

    return expand;

}

export const checkIfSelectionIsEmptyOrChangedHisValue = (showLastSelection: string[], uniqueKey: string): boolean => {

    let retval = false;
    if (showLastSelection.length === 0) {
        retval = true;
    }

    if (showLastSelection.length > 0) {
        const last = showLastSelection[showLastSelection.length - 1];

        if (last !== uniqueKey) {
            retval = true;
        }
    }
    return retval;
}

export const setSelectionforTreeView = (showLastSelection: string[], searchCriterion: string, showCalculatedSelection: string[]) => {
    if (showCalculatedSelection.length > 0) {

        let showlastSelectionRemover: string[] = [...showLastSelection];

        //When a selection is already set in the state and the last search criterion is the same, the last level will be removed.
        let updatedSelection = removeLastSelectionWhenItsAlreadySet(showlastSelectionRemover, searchCriterion);
        if (updatedSelection) {
            showLastSelection = updatedSelection;
        } else {
            showLastSelection = showCalculatedSelection;
        }

    } else if (showLastSelection.length >= 1) {

        if (showLastSelection[0] === searchCriterion) {
            showLastSelection = [];
        } else {
            showLastSelection = [searchCriterion];
        }
    }
    else {
        showLastSelection = [];
    }

    return showLastSelection
}