
import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { AppDispatch } from '../../../app/redux_store';
import { useTranslation } from 'react-i18next';
import {  Left, Right } from '../../../shared/shared';
import { SaveSortProducts, getPropertyNodesComponent } from '../redux/hierarchyAssignment.thunks';
import {  setSortTextBox, setStatusWhenMenuButtonClicked } from '../redux/hierarchyAssignment.slice';
import { useState } from 'react';


export const DialogSortWindowComponent = () => {
    const [open, setOpen] = React.useState(true);
    const { t } = useTranslation();
    const state = useAppSelector((state) => state.selectTreeView);
    const dispatch: AppDispatch = useAppDispatch();
    const [wasValueChanges, setWasValueChanges] = useState(false);

    const handleClose = () => {
        setOpen(false);
        
    };

    const saveNewSortingValue = async () => {
        if (wasValueChanges=== true) {
            await dispatch(SaveSortProducts());
        }

        await dispatch(getPropertyNodesComponent(null));
        await dispatch(setStatusWhenMenuButtonClicked(false));
        await handleClose();
    }

    const cancelToAddANewSortingValue = async () => {
        await dispatch(setStatusWhenMenuButtonClicked(false));
        await handleClose();
    }

    const returnHeadline = () => {
        if (state.data.setSortProductPropertyNode.productPropertyNodeModel.text) {

            return "Sortierung " + state.data.setSortProductPropertyNode.productPropertyNodeModel.text  +":"
        } else {
            return "Sortierung"
        }
    }

    const setTextField = (newText: string) => {
        setWasValueChanges(true);
        dispatch(setSortTextBox(newText));

    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                
            >
                <DialogTitle id="alert-dialog-title">
                    {"Sortierung festlegen" }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid alignContent= 'center'>
                        
                        <Left>
                                        <Typography variant="subtitle1">{returnHeadline()}</Typography>
                                </Left>
                                <Right>
                                <TextField variant='outlined' onChange={(x) => setTextField(x.target.value)} defaultValue={state.dataSort.sortTextBoxValue } helperText={state.dataSort.sortTextBoxErrorText}  error={state.dataSort.sortTextboxError} fullWidth></TextField>
                            </Right>
                        </Grid>
                        <Typography variant='subtitle1'></Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveNewSortingValue} disabled={state.dataSort.sortTextboxError } autoFocus>
                        {"Speichern"}
                    </Button>
                    <Button onClick={cancelToAddANewSortingValue} autoFocus>
                        {t("button_cancle")}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}