import { ThumbnailModel } from "../models/thumbnail.model";
import { ThumbnailComponent } from "../shared/components/buttons/thumbnail.component";
import administrator from './images/administrator.jpg';
import articlemaster from './images/ariclemaster.jpg';
import productmanager from './images/prodmOverview.jpg'
import { useTranslation } from 'react-i18next';
import * as style from "./home.style";


export const HomeComponent = () => {
    const { t } = useTranslation();
    const thumbnails: ThumbnailModel[] = [
       
        new ThumbnailModel(t("label_shortcut_productmanager"), productmanager, "/home/productmanagerpreselection"),
        new ThumbnailModel(t("label_shortcut_productmaster"), articlemaster, "/home/productmaster"),
        new ThumbnailModel(t("label_shortcut_Administrator"), administrator, "/home/export"),
    ];

    return (
        <>
            <style.ThumbnailWrapper>
                {thumbnails.map((thumb, i) => {
                    return (<ThumbnailComponent key={i} thumbnail={thumb} />)
                })}
            </style.ThumbnailWrapper>
        </>
    );
}