/* eslint-disable react-hooks/rules-of-hooks */
import {  useCallback, useEffect,useState } from "react";
import { AppDispatch} from 'app/redux_store';
import { useAppDispatch, useAppSelector } from 'app/hook';
import {  GetProductSortPreferencesSnapshot, GetSortValue, ResultpaginatedSkuList, getPropertyNodesComponent } from '../redux/hierarchyAssignment.thunks'
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import {  setIdWhenUseParamHaveADiffrentNumberAsPreselectionId } from 'admin/preSelection/redux/preselection.slice';
import { getSearchCriteria, setProductListFlag, filterSkus, getSearchCriteriaSku, addSelectedSku, setShowTableSku, setIsProductListFlagForDeleteSku, setHierarchyProperty, logicSelection, setStandardTreeView, setDetermineParamterForPaginatedSku, setStatusWhenMenuButtonClicked,  resetState, setUsedSortingValues } from '../redux/hierarchyAssignment.slice';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { IProductPropertyNodeModel, ISkuCheckboxData } from '../../../models/selectproductmanager/setproducts/productPropertyNodeModel';
import { loadPreselectionApi, selectProducts } from '../../preSelection/redux/preselection.thunks';
import { useTranslation } from 'react-i18next';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AssigmentStatus, IProductmaster } from '../../../models/productmastertable/productmastertable.model';
import "./hierarchyAssignment.component.css"
import { Checkbox, CircularProgress, Dialog,  Paper, TableCell, TablePagination, Tooltip, Typography, styled} from '@material-ui/core';
import { DeleteSearchCriteria, DeleteSearchCriteriaSku, initComponentPm,initComponentSku,SearchCriteria, SearchCriteriaSku } from '../../productmaster/redux/productmaster.thunks';
import { useNavigate, useParams } from "react-router-dom"
import { LoadingOverlayComponent, LoadingOverlayContainer } from "../../../shared/components/loading_overlay/loading_overlay.component";
import { dataCheckDisplayCircularProgress } from "../redux/hierarchyAssignment.reducer";
import { setTreeViewTable } from "./hierarchyAssigmentreducer";
import { PublishedIconComponent } from '../../../shared/components/icons/published_icon.component';
import { FlexBox, Left, PrimaryButtonComponent, Right } from "shared/shared";
import RoleBackIcon from '@material-ui/icons/NavigateBefore'
import SearchFieldComponent from "shared/components/search/searchfield.component";
import AddIcon from '@material-ui/icons/Add';
import { Menu} from "@material-ui/core";
import {  MenuOverviewComponentSort } from "../../../shared/components/overview/menu_overview.component";
import { DialogSortWindowComponent } from '../../hierarchyAssignment/dialogwindow/dialogSortWindow.component';

export const HierarchyAssignmentComponent = () => {

    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.selectTreeView);
    const stateTable = useAppSelector((state) => state.dataGrid);
    const stateselection = useAppSelector((state) => state.selectproduct);
   
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(28);
    const [page, setPage] = useState(0);
    const [selectedSku, setSelectedSku] = useState(null);

    const { id } = useParams();
    const navigateTo = useCallback((id: number) => {

        navigate(`/home/hierarchyassignment/${id}`);
    }, [navigate]);

    const [loading, setLoading] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState(null);
   
    useEffect(() => {
        return () => {
            dispatch(resetState());//reset
        };
    }, [dispatch]);

    useEffect(() => {

        let retval = false;
        if (!refresh) {
            if (id !== '0' && stateselection.loadedData.preselectionId.id === 0 && id !== stateselection.loadedData.preselectionId.id.toString()) {

                dispatch(setIdWhenUseParamHaveADiffrentNumberAsPreselectionId(id));
                setRefresh(true);
                navigateTo(parseInt(id));
            } else {
                dispatch(loadPreselectionApi());
            }

            if (!retval && stateselection.loadedData.preselectionId.id !== 0) {
                navigateTo(stateselection.loadedData.preselectionId.id);
            }
        }

    }, [navigateTo, stateselection.loadedData.preselectionId.id, dispatch, id, refresh]);

    useEffect(() => {

        return () => {
            if (stateselection.data.continue) {
                dispatch(selectProducts());
            }
            dispatch(setHierarchyProperty(stateselection.loadedData.preselectionId.id !== 0 ? stateselection.loadedData.preselectionId.id : parseInt(id)));
            dispatch(getPropertyNodesComponent(null));
        };

    }, [dispatch, stateselection.data.continue, stateselection.loadedData.preselectionId.id, id]);

    useEffect(() => {
        return () => {

            dispatch(initComponentPm());
        };
    }, [dispatch])

    //---- menu  ----

    const onMenuOpen = async (event, node: IProductPropertyNodeModel) => {
        await dispatch(GetSortValue(node));
        await setMenuAnchor(event.currentTarget);
        await dispatch(setUsedSortingValues());
        await dispatch(GetProductSortPreferencesSnapshot(node));
        await dispatch(setStatusWhenMenuButtonClicked(true));
    };

    const closeSortDialog = () => {
         dispatch(setStatusWhenMenuButtonClicked(false));
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const renderNodeTree = (nodes: IProductPropertyNodeModel[]) => (

        nodes.map((node) => {

            return (
                <>


                    <LoadingOverlayContainer>

                        {dataCheckDisplayCircularProgress(state.data.propertySearchcriterion, node, state.data.isHierarchyPending) === true ? (
                            <>
                                <CircularProgress style={{ color: "#E20074" }} size={30} color={"primary"} />

                            </>
                        ) : (
                            <>
                                    <TreeItem key={node.uniqueKey} nodeId={node.uniqueKey} label={
                                        <div className="TreeViewNodeWithMenuPoint">  
                                            {node.text}
                                            {node.text && node.text.trim() !== "" && (
                                                <MenuOverviewComponentSort
                                                    onMenuOpen={(event) => onMenuOpen(event, node)}
                                                    menuItem={node}
                                                    key={node.uniqueKey}
                                                />
                                            )}
                                        </div>
                                    }>
                                    {Array.isArray(node.children) ? renderNodeTree(node.children) : null}
                                </TreeItem>
                            </>
                        )}
                    </LoadingOverlayContainer>
                </>
            );

        })
    )


    const handleSelect = async (event, uniqueKey: string) => {

        event.persist();
     
        let iconClicked = event.target.closest(".MuiTreeItem-iconContainer");

        if (!iconClicked) {

            dispatch(logicSelection({ unique: uniqueKey, preselectionLength: stateselection.data.selectPreSelection.length }));
            await dispatch(initComponentPm());
        } else {
            await dispatch(getPropertyNodesComponent({ unique: uniqueKey, paging: stateselection.loadedData.preselectionId.id !== 0 ? stateselection.loadedData.preselectionId.id : parseInt(id) }));
        }
      
    }

    const handleSelectSku = async (event, uniqueKey: string) => {

        event.persist();
        dispatch(setShowTableSku(true));
        dispatch(addSelectedSku(uniqueKey));
        dispatch(initComponentSku());
        setSelectedSku(uniqueKey);
    }

    const checkBox = async (key: IProductmaster) => {

        await (dispatch(getSearchCriteria(key.id)))

        if (key.assignment !== 0) {
            //delete
            await dispatch(DeleteSearchCriteria()); //call delete api
        } else {
            //add
            await dispatch(SearchCriteria()) //call add api 
        }
        await dispatch(initComponentPm());
    }

    const checkBoxSku = async (key: IProductmaster) => {


        let getdata: ISkuCheckboxData = {

            key: key.id,
            sku: state.dataSku.sku
        }
        await (dispatch(getSearchCriteriaSku(getdata)))


        if (key.assignment !== 0) {
            // check if
            await dispatch(setIsProductListFlagForDeleteSku(key.inherit))
            await dispatch(DeleteSearchCriteriaSku()); //call delete api
        } else {
            //add
            await dispatch(SearchCriteriaSku()) //call add api 
        }
        await dispatch(initComponentSku());
    }

    const displayCheckbox = (productmaster: IProductmaster, onClick: (IProductmaster) => void) => {
        let checkBoxComponent = <></>;

        switch (productmaster.assignment) {

            case AssigmentStatus.None:
                checkBoxComponent = <Checkbox checked={!!productmaster.defaultCheckboxValue} indeterminate={!!productmaster.indeterminateValue} onClick={() => onClick(productmaster)}  ></Checkbox>;
                break;
            case AssigmentStatus.Indeterminate:
                checkBoxComponent = <Checkbox indeterminate={!!productmaster.indeterminateValue} checked={!!productmaster.defaultCheckboxValue} onClick={() => onClick(productmaster)} ></Checkbox>;
                break;

            case AssigmentStatus.All:
                checkBoxComponent = <Checkbox checked={!!productmaster.defaultCheckboxValue} indeterminate={!!productmaster.indeterminateValue} onClick={() => onClick(productmaster)}  ></Checkbox>;
                break;
            case AssigmentStatus.Inherit:
                checkBoxComponent = <Checkbox color="primary" checked={!!productmaster.defaultCheckboxValue} indeterminate={!!productmaster.indeterminateValue} onClick={() => onClick(productmaster)}  ></Checkbox>

        }

        if (productmaster.skus && Object.keys(productmaster.skus).length) {
            return (
                <CustomTooltip title={createTableContent(productmaster.skus)} arrow placement="bottom-start">{checkBoxComponent}</CustomTooltip>
            );
        }
        else {
            return checkBoxComponent;
        }
    }

    const createTableContent = (record: Record<string, boolean>) => {
        const entries = Object.entries(record);
        return (
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableBody>
                        {entries.map(([key, value]) => (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell align="right">{value ? '✘' : '✔'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };


    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .MuiTooltip-tooltip`]: {
            backgroundColor: '#FFFFFF',
            color: 'rgb(72, 88, 106)',
            fontSize: theme.typography.pxToRem(12),
            minWidth: 'auto',  // Ensure min-width is auto
            maxWidth: 'none',  // Remove default max-width constraint
            whiteSpace: 'nowrap',  // Prevent content from wrapping
        },
    }));

    const getProductlist = async () => {
        dispatch(setProductListFlag(true));
        dispatch(filterSkus(""));
        await dispatch(setDetermineParamterForPaginatedSku({ page: page, size: rowsPerPage, skuParameter: { nodes: [], text: "" } }));
        await dispatch(ResultpaginatedSkuList());
    }

    const gotoTreeView = async () => {
        await setLoading(true);
       
        await dispatch(setStandardTreeView());
        await dispatch(initComponentPm());
        await setLoading(false);
        setSelectedSku(null);
        setPage(0);
        setRowsPerPage(28);
    }

    const filterSku = async (filterText: string) => {
        await dispatch(filterSkus(filterText));
      
        await dispatch(setDetermineParamterForPaginatedSku({ page: 0, size: rowsPerPage, skuParameter: { nodes: [], text: "" } }));
        await dispatch(ResultpaginatedSkuList());
    }


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        dispatch(setDetermineParamterForPaginatedSku({ page: newPage, size: rowsPerPage, skuParameter: { nodes: [], text: "" } }));
        dispatch(ResultpaginatedSkuList());

    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {

        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        dispatch(setDetermineParamterForPaginatedSku({ page: 0, size: rowsPerPage, skuParameter: { nodes: [], text: "" }}));
        dispatch(ResultpaginatedSkuList());
    };

    return (
        <>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleMenuClose}
            >

            </Menu>

            <Dialog open={state.data.wasMenuButtonClicked} onClose={closeSortDialog}>
                <DialogSortWindowComponent />
            </Dialog>
           
            {(stateTable.isTablePending || state.data.isHierarchyPending || state.dataSku.isFilterSkuPending) && <LoadingOverlayComponent/>}
                {state.dataSku.showProductlist === true ?
                    <>
                        <div>

                            <div className="TreeViewComponent">

                                <div className="HierarchyAssigment">

                                    <FlexBox>
                                        <Left>
                                            <SearchFieldComponent placeholder={t("label_searchbar")} onSearch={async (filterText) => await filterSku(filterText)} />
                                        </Left>

                                </FlexBox>
                                <Table size="small">
                                    <TableBody> {state.dataSku.paginatedSkuList.content.data.map((sku) => { return <TableRow hover key={sku} selected={selectedSku === sku}  > <TableCell onClick={(t) => handleSelectSku(t, sku)} color={"black"} id={sku}>{sku}</TableCell> </TableRow> }) }</TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[state.dataSku.paginatedSkuList.content.pageCount]}
                                    component="div"
                                    count={state.dataSku.paginatedSkuList.content.totalCount}
                                    rowsPerPage={state.dataSku.paginatedSkuList.content.pageSize}
                                    page={state.dataSku.paginatedSkuList.content.page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    
                                />
                                </div>
                            </div>

                            <div className="TableOverview">
                                <FlexBox>
                                    <Right>
                                        <PrimaryButtonComponent onClick={gotoTreeView}    disabled={loading} >
                                            <RoleBackIcon /> {t("button_goback")}
                                            {loading && ( <LoadingOverlayComponent/>)}
                                        </PrimaryButtonComponent>
                                    </Right>
                                </FlexBox>
                                <br />
                                {state.dataSku.showSkuTable === true ? <>
                                    <div className="HeadlineTable">
                                        <div className="HeadlineText">
                                            {<h6>{state.data.headlineCompositionOfSearchCriteria}</h6>}
                                        </div>
                                    </div>



                                    {setTreeViewTable(state.data.showTable, state.data.dataCheckDisplayCircularProgress) === true ? <>
                                            <div className="SelectedProductmaster" >
                                                <Typography variant="subtitle1"  ><b>{t("table_selected_Productmaster")}</b></Typography>
                                                <div className="SelectedTable">
                                                    <TableContainer >


                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow></TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {stateTable.loadedData.availabletabledata.map((e) => (
                                                                    <TableRow key={e.id} >
                                                                        {e.sortedId % 2 === 0 ?
                                                                            <>

                                                                                <TableCell padding="checkbox" className='ungerade' >{displayCheckbox(e, checkBoxSku)}</TableCell>
                                                                                <TableCell padding="none" className='ungerade' >{e.abbreviation} {e.name} </TableCell>
                                                                                <TableCell padding="none" className='ungerade'> {e.dynamic === true ? <PublishedIconComponent /> : ""}</TableCell>
                                                                            </>

                                                                            : <>

                                                                                <TableCell padding="checkbox">{displayCheckbox(e, checkBoxSku)}</TableCell>
                                                                                <TableCell padding="none">{e.abbreviation} {e.name}</TableCell>
                                                                                <TableCell padding="none"> {e.dynamic === true ? <PublishedIconComponent /> : ""}</TableCell>
                                                                            </>
                                                                        }

                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table >

                                            </TableContainer>
                                            
                                                </div>
                                            </div>

                                            <div className="AvalibleProductmaster">
                                                <Typography variant="subtitle1"><b>{t("table_available_Productmaster")}</b></Typography>
                                                <div className="AvalibleTable">
                                                    <TableContainer >
                                                        <Table size="small" >
                                                            <TableHead>
                                                                <TableRow></TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {stateTable.loadedData.selectedtabledata.map((e) => (
                                                                    <TableRow
                                                                        key={e.id}
                                                                    >
                                                                        {e.sortedId % 2 === 0 ?
                                                                            <>

                                                                                <TableCell padding="checkbox" className='ungerade' >{displayCheckbox(e, checkBoxSku)}</TableCell>
                                                                                <TableCell padding="none" className='ungerade' >{e.abbreviation} {e.name} </TableCell>
                                                                                <TableCell padding="none" className='ungerade'> {e.dynamic === true ? <PublishedIconComponent /> : ""}</TableCell>
                                                                            </>

                                                                            : <>

                                                                                <TableCell padding="checkbox">{displayCheckbox(e, checkBoxSku)}</TableCell>
                                                                                <TableCell padding="none">{e.abbreviation} {e.name} </TableCell>
                                                                                <TableCell padding="none"> {e.dynamic === true ? <PublishedIconComponent /> : ""}</TableCell>
                                                                            </>

                                                                        }



                                                                    </TableRow>

                                                                ))}

                                                            </TableBody>

                                                        </Table>

                                                    </TableContainer>
                                            

                                                </div>

                                            </div>
                                    </> : <></>}
                                </> : <></>}
                            </div>

                        </div>

    
                    </> :

                    <>
                        <div>
                        
                            <div className="TreeViewComponent">


                                <div className="HierarchyAssigment">

                                    <TreeView
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    onNodeSelect={async (event, nodeId)=> handleSelect(event,nodeId)}
                                    defaultParentIcon={<ExpandMoreIcon />}
                                    expanded={state.dataSku.showLastSelection}
                                    multiSelect={false}
                                    color={"black"}
                                    >
                                    
                                    {renderNodeTree(state.data.copyoldPropertyflag === false ? state.data.propertyChildren : state.data.propertyChildrenOld)}
                                    </TreeView>
                                </div>
                            </div>
                       
                            <div className="TableOverview">
                           
                                {state.data.showTable === true ? <>
                                    <FlexBox>
                                        <Right>
                                            <div className="Productlist">
                                                <PrimaryButtonComponent onClick={getProductlist} hidden={state.dataSku.visibleProductListButton} >
                                                    <AddIcon /> {t("btn_showproductlist")}
                                                </PrimaryButtonComponent>
                                            </div>
                                        </Right>
                                    </FlexBox>
                                    <div className="HeadlineTable">
                                        <h6>{state.data.headlineCompositionOfSearchCriteria}</h6>
                                       
                                    </div>

                                </> : <></>}

                                {setTreeViewTable(state.data.showTable, state.data.dataCheckDisplayCircularProgress) === true ? <>
                                        <div className="SelectedProductmaster" >
                                            <Typography variant="subtitle1"  ><b>{t("table_selected_Productmaster")}</b></Typography>
                                            <div className="SelectedTable">
                                                <TableContainer >


                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow></TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {stateTable.loadedData.availabletabledata.map((e) => (
                                                                <TableRow key={e.id} >
                                                                    {e.sortedId % 2 === 0 ?
                                                                        <>

                                                                            <TableCell padding="checkbox" className='ungerade' >{displayCheckbox(e, checkBox)}</TableCell>
                                                                            <TableCell padding="none" className='ungerade' >{e.abbreviation} {e.name} </TableCell>
                                                                            <TableCell padding="none" className='ungerade'> {e.dynamic === true ? <PublishedIconComponent /> : ""}</TableCell>
                                                                        </>

                                                                        : <>

                                                                            <TableCell padding="checkbox">{displayCheckbox(e, checkBox)}</TableCell>
                                                                            <TableCell padding="none">{e.abbreviation} {e.name}</TableCell>
                                                                            <TableCell padding="none"> {e.dynamic === true ? <PublishedIconComponent /> : ""}</TableCell>
                                                                        </>
                                                                    }

                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table >

                                                </TableContainer>
                                            </div>
                                        </div>

                                        <div className="AvalibleProductmaster">
                                            <Typography variant="subtitle1"><b>{t("table_available_Productmaster")}</b></Typography>
                                            <div className="AvalibleTable">
                                                <TableContainer >
                                                    <Table size="small" >
                                                        <TableHead>
                                                            <TableRow></TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {stateTable.loadedData.selectedtabledata.map((e) => (
                                                                <TableRow
                                                                    key={e.id}
                                                                >
                                                                    {e.sortedId % 2 === 0 ?
                                                                        <>

                                                                            <TableCell padding="checkbox" className='ungerade' >{displayCheckbox(e, checkBox)}</TableCell>
                                                                            <TableCell padding="none" className='ungerade' >{e.abbreviation} {e.name} </TableCell>
                                                                            <TableCell padding="none" className='ungerade'> {e.dynamic === true ? <PublishedIconComponent /> : ""}</TableCell>
                                                                        </>

                                                                        : <>

                                                                            <TableCell padding="checkbox">{displayCheckbox(e, checkBox)}</TableCell>
                                                                            <TableCell padding="none">{e.abbreviation} {e.name} </TableCell>
                                                                            <TableCell padding="none"> {e.dynamic === true ? <PublishedIconComponent /> : ""}</TableCell>
                                                                        </>
                                                                    }


                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </div>
                                        </div>
                                </> : <></>}
                            </div>

                        </div>

                    </>}
        </>
    )
}











