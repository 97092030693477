import { PreSelectionOverviewState } from '../redux/preselection.model'
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPreSelection, initComponent, loadPreselectionApi, selectProducts } from './preselection.thunks';
import { ProductTypeEnum, ProductValues } from '../../../models/selectproductmanager/setproducts/productSelection_Type_Enum';
import { ISelectedProdfuctTypeEnum } from "models/productmaster/products/ISelectedProductTypeEnum";
import { IPreSelectedProductTypEnum } from "models/productmaster/products/IPreSelectedProductTypEnum";
import { setpossibleSelection, SortASC, updatePreselectionProductItems, UpdatePreSelectionUnEqualUndefined } from "./preselection.reducer";



const initialState: PreSelectionOverviewState = {
    loadedData: {
        productsCatergory: [],
        productsCatergoryPreselection: [],
        preselectionId:
        {
            id:0,
        },
    },
    data: {
       
        selectItems:[],
        selectPreSelection:[],
        continue: false,
        saveSelection: [],
        
    },
    command: {
        getProduct: { status: "idle", canExecute: true },
        getPreSelectionCommand: { status: "idle", canExecute: true },
        getPreSelectionCommandId: { status: "idle", canExecute: true }
    }
}

export const productsCreateSlice = createSlice({
    name: 'productsCreate',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            
        },

        updatePreSelectProductItem: (state, action: PayloadAction<IPreSelectedProductTypEnum>) => {
            state.data.continue = true
            
            let index = action.payload.index - 1;
            
            //add please select
            if (state.data.selectPreSelection[index].selectedEnum.value === ProductTypeEnum.Undefined) {
                let item = updatePreselectionProductItems(action.payload, state.data.selectPreSelection);
                
                state.data.selectPreSelection.push(item);
                
                state.data.selectPreSelection[index].selectedEnum.value = action.payload.selectedEnum.value;
                state.data.selectPreSelection[index].selectedEnum.text = action.payload.selectedEnum.text;

            } else {
                //add != please select
                let oldselected = state.data.selectPreSelection[index].selectedEnum;
                state.data.selectPreSelection = UpdatePreSelectionUnEqualUndefined(action.payload, state.data.selectPreSelection, oldselected);
            }

            const removedItem = action.payload.selectedEnum;

            state.data.selectPreSelection.forEach(x => {

                let newpossibleState = x.possibleStates.filter((r) => { return r.value !== removedItem.value })
                x.possibleStates = newpossibleState;
            });

            state.data.selectPreSelection = SortASC(state.data.selectPreSelection);
        },

        deleteSelection: (state) => {
            //clear selection
            
            state.data.continue = true
            state.data.selectPreSelection = [];
            let preSelection = state.data.selectPreSelection;
            preSelection[0] = { index: 1, selectedEnum: ProductValues[0], possibleStates: ProductValues.filter((item) => { return item !== ProductValues[0] && item !== ProductValues[21] }) };
            state.data.selectPreSelection = SortASC(preSelection);
        },

        deleteSingleSelection: (state, action: PayloadAction<ISelectedProdfuctTypeEnum>) => {
            state.data.continue = true
            let preSelection = state.data.selectPreSelection;

            if (action.payload.index > 0  && action.payload.selectedEnum.value !== 0) {
                //Find index
                let findIndex = preSelection.findIndex((find) => { return find.index === action.payload.index });
               
                preSelection = preSelection.filter((x) => { return x.index !== action.payload.index});

                preSelection.forEach((element, index) => {

                    if (index >= findIndex) {
                        preSelection[index].index = element.index - 1;
                    }
                });

                //add
                preSelection.forEach((x) => {
                    x.possibleStates.push(action.payload.selectedEnum);
                });
            }

            state.data.selectPreSelection = SortASC( preSelection);
        },
        setRefresh: (state, action: PayloadAction<boolean>) => {
        },
        setIdWhenUseParamHaveADiffrentNumberAsPreselectionId: (state, action: PayloadAction<string>) => {

            let paramValue = action.payload;

            if (paramValue !== '') {
                let pageNumber = parseInt(action.payload);
                state.loadedData.preselectionId.id = pageNumber;
            }

            
        },

    }, extraReducers: (builder) => {

         //fetch Events
        builder.addCase(selectProducts.pending, (state) => {
            state.command.getProduct.status = "pending"
            state.command.getProduct.canExecute = false;

        }).addCase(selectProducts.rejected, (state, action) => {
            state.command.getProduct.status = "error"
            state.command.getProduct.message = action.error.message;
            state.command.getProduct.canExecute = true;

        }).addCase(selectProducts.fulfilled, (state, action) => {
            state.command.getProduct.status = "success"
            state.command.getProduct.canExecute = true;
            let id = action.payload.getData().id;
            state.loadedData.preselectionId.id = id;
      

        }).addCase(initComponent.pending, (state) => {
            state.command.getProduct.status = "pending"
            state.command.getProduct.canExecute = false;
        }).addCase(initComponent.fulfilled, (state, action) => {
            state.command.getProduct.status = "success";
            state.loadedData.productsCatergory = action.payload.getData();
            state.data.selectItems = [{ index: 0, selectedEnum: action.payload.getData()[0], possibleStates: action.payload.getData() }];
        }).addCase(getPreSelection.pending, (state) => {
            state.command.getPreSelectionCommand.status = "pending"
            state.command.getPreSelectionCommand.canExecute = false;
        }).addCase(getPreSelection.fulfilled, (state, action) => {
            state.command.getPreSelectionCommand.status = "success";

            //setpossiblestate
            if (state.loadedData.productsCatergoryPreselection.length === 0) {
                state.loadedData.productsCatergoryPreselection = setpossibleSelection(state.loadedData.productsCatergory, action.payload.getData(), state.loadedData.productsCatergoryPreselection)
            }
            

            //fill Dropdown with preselection

            if (state.data.selectPreSelection.length === 0) {
                let i = 0;
                action.payload.getData().forEach((e) => {
                    i++;
                    state.data.selectPreSelection.push({ index: i, selectedEnum: e, possibleStates: state.loadedData.productsCatergoryPreselection })
                });

                state.data.selectPreSelection = SortASC(state.data.selectPreSelection);
            }

        }).addCase(loadPreselectionApi.pending, (state) => {
            state.command.getProduct.status = "pending"
            state.command.getProduct.canExecute = false;

        }).addCase(loadPreselectionApi.rejected, (state, action) => {
            state.command.getProduct.status = "error"
            state.command.getProduct.message = action.error.message;
            state.command.getProduct.canExecute = true;

        }).addCase(loadPreselectionApi.fulfilled, (state, action) => {
            state.command.getProduct.status = "success"
            state.command.getProduct.canExecute = true;
            let id = action.payload.getData().id;
            state.loadedData.preselectionId.id = id;
            

        });
    }
})

export const {
    resetState,  
    deleteSelection,  
    deleteSingleSelection,
    updatePreSelectProductItem,
    setRefresh,
    setIdWhenUseParamHaveADiffrentNumberAsPreselectionId
} = productsCreateSlice.actions

export default productsCreateSlice.reducer

